import store from '@/store'
import ability from '@/libs/acl/ability'

const handlerRoles = userRoles => {
  let userPermissions = []
  userRoles.forEach(role => {
    role.permissions.data.forEach(permission => {
      userPermissions.push({
        action: 'edit',
        subject: `${permission.name}`,
      })
      if (permission.conditions.data.length > 0) {
        const condition = permission.conditions.data.find(condition => condition.type.split('.').includes('prohibitions'))
        if (condition && condition.params.columns) {
          condition.params.columns.forEach(column => {
            userPermissions.push({
              action: 'edit',
              subject: `${condition.type}.${column}`,
            })
            console.log(`${condition.type}.${column}`)
          })
        }
      }
    })
  })
  console.log(userPermissions)
  ability.update(userPermissions)
  store.commit('user/setAbility', userPermissions)
}

export default handlerRoles
